<template>
    <router-link :to="'/channel/' + channel.id" v-if="wide ? (channel.asset && channel.asset.img) : true">
        <div class="channel" :class="{wide: wide, animated: loader, tfs: channel.country === '24/7'}" ref="bg">
            <div  class="logo" v-if="!loader">
                <img :src="channel.asset.img" v-if="channel.asset && channel.asset.img" loading="lazy" />
            </div>

            <div class="lip" >
                {{channel.name}}
                <span v-if="channel.country === '24/7'">
                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="none"> <path d="M2 20.01L2.01 19.9989" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M15 20H20C21.1046 20 22 19.1046 22 18V6C22 4.89543 21.1046 4 20 4H4C2.89543 4 2 4.89543 2 6V7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2 16C4 16.5 5.5 18 6 20" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2 12C6 12.5 9.5 16 10 20" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                    station
                </span>
            </div>
        </div>
    </router-link>
</template>

<script>

export default {
  name: 'channel',
  props: {
    channel: Object,
    wide: {
        type: Boolean,
        default: function(){ return false }
    }
  },

  computed: { },

  data: function(){
    return {
        loader: false,
        loaded: false
    }
  },

  methods: {

  },

  mounted: function(){
    this.$nextTick(() => {
        if(this.channel.asset && this.channel.asset.colors && this.channel.asset.colors.Vibrant){
            this.$refs.bg.style.backgroundColor = window.getAssetColor(this.channel.asset)
            this.$refs.bg.style.borderColor = window.getAssetColor(this.channel.asset).replace('0.8', '1')
        }
    })
  }
}
</script>

<style scoped lang="scss">
.channel{
    width: 140px;
    height: 160px;
    border-radius: 5px;
    background-color: #333;
    margin: 0 2.5px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    overflow: hidden;
    position: relative;
    color: black;
    font-weight: bold;
    display: flex;
    color: white;
    flex-direction: column;
    justify-content: space-between;
    border: 4px solid grey;

    .lip{
        width: 75%;
    }
    .logo{
        height: 120px;
        width: 100px;
        overflow: hidden;
        position: absolute;
        right: -10px;
        bottom: -25px;
        z-index: 0;
        display: flex;
        img{
            position: relative;
            align-self: center;
            border-radius: 90px;
            width: auto;
            max-height: 105px;
        }

    }
    &.tfs{
        border: none!important;
        .channel{
            padding: 0;
            margin: 0;
            height: 160px;
        }
        .lip{

            position: absolute;
            z-index: 99;
            bottom: 15px;
            left: 20px;
            height: calc(100% - 40px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            span{
                font-size: 7px;
                font-weight: bold;
                padding-top: 10px;
                display: flex;
                align-items: center;
                text-transform: uppercase;
                svg{
                    width: 10px;
                    padding-right: 10px;
                }
            }
        }
        .logo{
            width: 110%;
            top: -5%;
            filter: blur(2px);
            bottom: unset;
            left: -5%;
            right: unset;
            height: 110%;
            opacity: 0.65;
            max-width: none;
            max-height: none;
            img{
                width: 100%;
                height: auto;
                border-radius: 0;
                max-width: none;
                max-height: none;

            }

        }

    }
    &.wide{
        width: 200px;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        .lip{
            display: none;
        }
        .logo{
            position: relative;
            width: 80%;
            height: auto;
            top: 0;
            left: 0;

            img{
                max-width: 80%;
                border-radius: 0;
                margin: 0 auto;
            }
        }

    }
}
</style>
